<template>
  <div id="home">
    <!-- 关于 -->
    <div class="ind_main">
      <div class="ind_content">
        <el-row>
          <el-col id="introduce" :span="24">
            <div class="ind_top top_font">
              <p>柏帝 /</p>
              <p>ABOUT</p>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24"><p>在公司发展壮大的3年里，我们始终为客户提供好的产品和技术支持、健全的售后服务，我公司主要经营服务、医疗器械、光学分析仪器、自动化设备、电子产品及配件、生物试剂、仪器仪表技术开发、技术服务、技术咨询、成果转让;计算机软硬件、计算机信息技术。我们有好的产品和专业的销售和技术团队，我公司属于杭州医疗器械制造设备公司行业，如果您对我公司的产品服务有兴趣，期待您在线留言或者来电咨询。</p></el-col>
        </el-row>
        <el-row>
          <el-col>
            <h2 id="provide" style="text-align: center;">提供服务</h2>
          </el-col>
          <el-col :span="24" class="provide">
            <div>
              <div class="item_icon1" />
              <p style="padding-top:10px;text-align: center; font-size: 16px; font-weight: bold !important;text-indent: 0em;"> 软件定制</p>
              <p style="padding-top:10px;text-align: center; font-size: 16px; !important;text-indent: 0em;">.net，java等各种软件定制</p>
            </div>
            <div>
              <div class="item_icon2" />
              <p class="cp_title" style="padding-top:10px;text-align: center; font-size: 16px; font-weight: bold !important;text-indent: 0em;"> 网站开发</p>
              <p style="padding-top:10px;text-align: center; font-size: 16px; !important;text-indent: 0em;">各类网站快速开发</p>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <h2 id="product" style="text-align: center;">产品</h2>
          </el-col>
          <el-col :span="24" class="provide">
            <div style="width:300px;height:300px"><img style="width:300px;height:300px" src="../../public/img/cp1.jpg" alt="" srcset=""></div>
            <div style="width:300px;height:300px"><img style="width:300px;height:300px" src="../../public/img/cp2.jpg" alt="" srcset=""></div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}
</script>
<style >
.provide{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.item_icon1{
  width: 200px;
  height: 200px;
  text-align: center;
  line-height: 120px;
  background:url('../../public/img/rjdz.png') center center no-repeat;
  border-radius: 50%;
  box-shadow: 10px 10px 10px rgb(0 0 0 / 5%);
}
.item_icon2{
  width: 200px;
  height: 200px;
  text-align: center;
  line-height: 120px;
  background:url('../../public/img/wzdz.png') center center no-repeat;
  border-radius: 50%;
  box-shadow: 10px 10px 10px rgb(0 0 0 / 5%);
}
#books{
  margin: 0px auto;
  height: auto !important;
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  padding-bottom: 5%;
}
#e_book{
  margin: 20px auto;
  background-color: white;
  height: 100%;
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.books{
  height: 35vh;
  width: 80%;
  margin-right: -5%;
}
.books img{
  border: 1px solid lightgray;
  box-shadow: 2px 2px 5px 2px lightgray;
  max-height: 100%;
  max-width: 100%;
}
.main_to{
  background-color: lightgray;
  height: 90px;
  width: 100%;
}
#introduce{
  background-color: lightgray;
}
.ind_top{
  height: 100%;
  width: 100%;
  background-size: 100% 100%;
  background-image: url('../../public/img/about_bg.png');
  padding: 5% 0px;
}
.top_font p{
  color: white !important;
  text-align: left !important;
}
.ind_main{
  margin: 0px auto;
  height: auto !important;
  height: 100%;
  min-height: 100%;
  padding-top: 90px;
  overflow-y: auto;
}
.ind_content{
  border-radius: 2px;
  background-color: white;
  width: 60%;
  text-align: center;
  margin: 0px auto;
  padding: 20px 0px;
}
h1{
  font-weight: 500;
  text-align: center;
}
h2{
  text-align: left;
}
h4{
  margin-top: 0px !important;
  font-weight: 200 ;
}
.el-col{
  padding: 18px;
}
.ind_content .el-col img{
  width:96%;
}
.el-col p{
  letter-spacing: 2px;
  line-height: 2em;
  color:#666;
  text-indent: 2em;
  text-align: left;

}
@keyframes flow{
  from{
    -webkit-transform:translateY(0px);
            transform:translateY(0px);
  }
  to{
    -webkit-transform:translateY(-1000px);
            transform:translateY(-1000px);
  }
}
@media (max-width:1030px){
  .txt_box{
    margin: 10px 0px;
    padding: 0px;
  }
  .ind_main{
    padding-top: 45px;
  }
  .ind_content{
    width: 92%;
  }
  h1{
    font-size: 20px;
  }
}
/* 证书 */
#certificate{
  background: lightgray;
  padding-top: 90px;
  width: 100%;
  margin: 0px auto;
}
.certificate_main{
  padding-top: 90px;
  width: 60%;
  margin: 0px auto;
}
.cert_card{
  height:30vh;
  width: 10vw;
  border:1px solid red;
  margin: 0px auto;
}
.cert_card img{
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: 100%;
}
#supports{
  background-color: lightgray;
}
.sup_main{
  margin: 0px auto;
  height: auto !important;
  height: 100%;
  min-height: 100%;
  padding-top: 90px;
}
.top-tit{
  padding-top: 5%;
  font-weight: 300;
  background-color: white;
  text-align: center;
}
.top-tit hr{
  margin: 5px auto;
  width: 150px;
}
.sup_content{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: white;
  width: 100%;
}
ul{
  list-style: none;
}
li{
  text-align: center;
}
h1{
  text-align: center;
}
/* new */
.img_map img{
  width: 20%;
  margin: 0px auto;
}
.imgbox{
  width:33%;
  height:500px;
  margin:0px auto;
  overflow: hidden;
}
.img_list{
  height:100%;
  animation: flow 15s linear 0s infinite alternate;
}
.img_list img,.img_map img {
  box-shadow: 2px 2px 3px lightgray;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.img_map img{
          box-shadow: none;
}
@media (max-width:1030px){
  .main_to{
    background-color: lightgray;
    height:45px;
    width: 100%;
}
  .img_map{
    display: none;
  }
  .sup_main{
    padding-top: 45px;
  }
  .sup_content{
    padding: 5% 0px;
  }
  .img_list:hover{
    -webkit-animation-play-state:running;
            animation-play-state:running;
  }
  /* top-tit区 */
  .top-tit h1{
    font-size: 20px;
  }
  .top-tit h3{
    font-size: 14px;
  }
  .top-tit hr{
    margin: 0px auto;
    width: 120px;
  }
  .imgbox{
    height:450px;
  }
}
</style>

